export default {
  title: 'Order history',
  search: 'Searching...',
  order: 'Order',
  deleteOrder: 'Delete order',
  canceled: 'Cancel',
  total: 'Total:',
  flowers: 'Flowers',
  paid: 'Paid',
  forPayment: 'For payment',
  cancel: 'Cancel',
  apply: 'Apply',
  edit: 'Edit',
  delivery: 'Delivery',
  toReturn: 'To return',
  saveDeliveryMethod: 'Save', 
  pay: 'Pay',
  saveStatus: 'Save',
  user: 'User',

  address: 'Address',
  city: 'City',
  country: 'Country',
  company: 'Company',
  phone: 'Phone',
  name: 'Name',
  surname: 'Surname',
  index: 'Index',
  inn: 'Inn',

  new: 'New',
  confirmed: 'Confirmed',
  assembled: 'Assembled',
  shipped: 'Shipped',
  delivered: 'Delivered',
  return: 'Return',

  useHead: {
    historyTitle: 'Order history',
    orderTitle: 'Order',
  },
  history: {
    height: 'Height:',
    cm: 'cm',
    quantity: 'Quantity:',
    pc: 'piece',
    totalCost: 'Total cost:',
    deliveryFrom: 'Delivery from:',
    pricePerPiece: 'Price per piece:',

  },
  edited: {
    height: 'Height:',
    cm: 'cm',
    price: 'Price:',
    flower: 'flower',
    forAll: 'for everything',
    flowers: 'flowers',
    inStock: 'In stock:',
  },
  notification: {
    emptyOrder: 'Cannot save empty order',
    emptyPos: 'Delete positions that have a zero balance',
    error: 'An unexpected error occurred',
    confirmDel: 'Are you sure you want to delete this order',
    confirmCancel: 'Are you sure you want to cancel this order',
  },
}