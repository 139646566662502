import { apiAccount } from '@/services';
import { useAccountModel } from '@/models';
import type { Account, AccountModel } from '@/interfaces';

export const useAccountStore = defineStore('account', () => {
  const list = ref<AccountModel[]>([]);
  const is = reactive({
    init: false,
  });

  const getters = {
    getList: computed(() => list.value),
    getIsInit: computed(() => is.init),
  };

  const setIsInit = (value: boolean) => (is.init = value);
  const setItem = (value: Account) => {
    list.value.push(useAccountModel(value));
  };

  const loadList = async () => {
    if (is.init) return;
    list.value = [];
    apiAccount.getList()
      .then(({ data }) => {
        for (const item of data) setItem(item);
        setIsInit(true);
      })
      .catch(err => {
        console.error(err)
      })
  };

  const updateAmount = (value: Account) => {
    const account = list.value.find((a) => a.getId === value._id);
    if (account) account.updateAmount(value.amount);
  };

  const reset = () => {
    list.value = [];
    is.init = false;
  };

  const updateBalance = () => {
    list.value = [];
    apiAccount.getList()
      .then(({ data }) => {
        for (const item of data) setItem(item);
      })
      .catch(err => {
        console.error(err)
      })
    return true
  };

  return {
    ...getters,

    reset,
    loadList,
    updateAmount,
    updateBalance,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot));
}
