export default {
  useHead: {
    placeAnOrder: 'Оформление заказа',
  },
  placeAnOrder: 'Оформление заказа',
  checkTransactionDetails: 'Проверьте детали операции',
  orderList: 'Состав заказа:',
  delivery: 'Доставка',
  pc: 'шт.',
  receiptInformation: 'Информация о получении:',
  pickup: 'Самовывоз:',
  address: 'г. Раменское, ул. Михалевича 151В(для самовывоза позвоните +7(499)288-74-78)',
  totalPayable: 'Итого к оплате:',
  back: 'Назад',
  order: 'Оформить',
  orderIsProcessed: 'Заказ оформлен',
  successfulOperation: 'Успешная операция.',
  paymentCompleted: 'Оплата заказа прошла.',
  toCatalog: 'В каталог',
  historyOfOrders: 'История заказов',
  payOrder: 'Оплатить заказ',
  successOrderIsProcessed: 'Заказ успешно сформирован!',

  payOrderCard: 'Карта',
  payOrderСheck: 'Счет',
  payOrderCash: 'Наличные',
  payOrderBalance: 'С Баланса',

  selectPaymentMethod: 'Выберите способ оплаты',
  selectPaymentOrder: 'Оплатите ваш заказ',

  paidOrderCard: 'Оплатить картой',
  paidOrderCash: 'Оплатить наличными',
  paidOrderBalance: 'Оплатить с баланса',

  deliveryMethod: 'Способ доставки',
  paymentMethod: 'Способ оплаты',

  CHECK: 'Счет',
  CARD: 'Карта',
  CASH: 'Наличные',
  BALANCE: 'Баланс',

  BALANCE_MINUS: 'Будет списано с баланса',

  paymentOrder: 'Оплатить заказ',
  paymentGo: 'Перейти к оплате',
}
 